*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font: inherit;
}

html {
  font-family: "Nunito Sans", sans-serif;
}
